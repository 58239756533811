jQuery(document).ready(function($) {
    $('.big-center-slider-wrapper .owl-carousel').owlCarousel({
        autoplay: true,
        autoplayHoverPause: true,
        autoplaySpeed: 600,
        center: true,
        items:2,
        afterInit: function () {
            owl.find('.owl-wrapper').each(function () {
                var w = $(this).width() / 2;
                $(this).width(w);
                $(this).css('margin', '0 auto');
            });
        },
        afterUpdate: function () {
            owl.find('.owl-wrapper').each(function () {
                var w = $(this).width() / 2;
                $(this).width(w);
                $(this).css('margin', '0 auto');
            });
        },
        loop:true,
        margin:20,
        nav:true,
        
    });

}); /* end of as page load scripts */